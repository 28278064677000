import React from 'react'
import TextSnippet from '../../icons/TextSnippet'
import Card from '../Card/Card'
import CardHeader from '../Card/CardHeader'
import CardIcon from '../Card/CardIcon'
import Button from '../CustomButtons/Button'
import Add from '@material-ui/icons/Add'
import CardBody from '../Card/CardBody'
import Table from '../Table/Table'
import { useTranslation } from 'react-i18next'
import { useCustomerResources } from './hooks/useCustomerResources'
import { CardFooter } from '../Card'
import CustomDialog from '../CustomDialog'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { TextField, Tooltip } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import ConfirmAlert from '../ConfirmAlert'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CustomNotificationBadge } from '../CustomNotificationBadge'

export const CustomerResources = ({
  readOnlyMode,
  personId,
  fullName,
}: {
  readOnlyMode: boolean
  personId: number
  fullName: string
}) => {
  const { t } = useTranslation()

  const {
    tableData,
    docModalOpen,
    handleModalActions,
    handleOpenModal,
    canAddResource,
    form,
    error,
    handleDragOver,
    handleDragLeave,
    dragOver,
    handleDrop,
    handleFileInputChange,
    handleChange,
    canUploadResource,
    deleteAlert,
    closeAlert,
    success,
    alertMessage,
    handleDeleteResource,
    isFetching,
  } = useCustomerResources({ readOnlyMode, personId, fullName })

  const { detail, file } = form

   return (
    <>
      <Card className="employee-phones">
        <CardHeader icon>
          <div className="header-internment-table">
            <div className="header-icon vehicle-title">
              <CardIcon color="primary">
                <TextSnippet />
              </CardIcon>
              <h3 className="card-icon-title">
                {t('customers.new.attatchments') as string}
              </h3>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table {...tableData} />
        </CardBody>

        {!readOnlyMode && (
          <CardFooter className="content-button-add flex-end">
            <Tooltip
              title={
                !canAddResource
                  ? (t(
                      'customers.new.create.attatchment.maxDocsAlert'
                    ) as string)
                  : ''
              }
            >
              <span>
                <Button
                  color="primary"
                  authority="appointment_new"
                  onClick={handleOpenModal}
                  disabled={!canAddResource}
                >
                  <Add className="" />{' '}
                  {t('customers.new.create.attatchment') as string}
                </Button>
              </span>
            </Tooltip>
          </CardFooter>
        )}
      </Card>
      <CustomDialog
        open={docModalOpen}
        onClose={() => handleModalActions('close')}
        title={t('customers.new.attatchments.modal.title') as string}
      >
        {isFetching ? (
          <div
            style={{
              display: 'flex',
              height: '200px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <GridContainer className={''}>
            <GridItem xs={12} sm={12} md={12} className="componentType">
              <TextField
                id="customerAttatchment__detail"
                disabled={readOnlyMode}
                value={detail}
                label={t('customers.new.resources.detail.input') as string}
                name={'detail'}
                onChange={handleChange}
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                inputProps={{maxLength: 200}}
              ></TextField>
              {error && <CustomNotificationBadge notification={error} type={"error"}/>}
              <div
                style={{
                  textAlign: 'center',
                  border: dragOver ? '2px dashed #000' : '2px dashed #ccc',
                  padding: '20px',
                  borderRadius: '10px',
                  backgroundColor: dragOver ? '#eee' : '#fff',
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <CloudUpload style={{ fontSize: '50px' }} />
                <p>{t('customers.new.resources.upload.drag.message') as string}</p>
                <Button variant="contained" component="label">
                  {t("customers.new.resources.upload.file.message") as string}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileInputChange}
                    accept=".pdf, .png, .jpg, .jpeg, .docx, .xlsx"
                  />
                </Button>
              </div>
              {file && <p>{t("customers.new.resources.file.name") as string} {file?.name}</p>}
              <div>
                <Button
                  variant="secondary"
                  onClick={(e: any) => {
                    e.preventDefault()
                    handleModalActions('close')
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant="default"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    handleModalActions('add')
                  }}
                  disabled={!canUploadResource}
                >
                  {t("upload")}
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        )}
      </CustomDialog>
      <ConfirmAlert
        show={deleteAlert}
        text={t('customers.new.common.resource')}
        onClose={() => closeAlert()}
        onConfirm={() => handleDeleteResource()}
        success={success}
        onSuccess={() => closeAlert()}
        messageDeletedUnsuccess={alertMessage}
        showCancelSuccess={false}
      />
    </>
  )
}
