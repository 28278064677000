import "./Traslado.css";

import {
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import React, { Component } from "react";

import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Button from "../../CustomButtons/Button";
import CustomInput from "../../CustomInput/CustomInput";
import DateInput from "../../DateInput";
import Finished from "../../../assets/img/finished_trans.png";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Pending from "../../../assets/img/pending.png";
import PendingDo from "../../../assets/img/pending_do_trans.png";
import PinCurrent from "../../../assets/img/pin_current.png";
import PinDestination from "../../../assets/img/pin_destination.png";
import PinSource from "../../../assets/img/pin_source.png";
import PropTypes from "prop-types";
import Reject from "../../../assets/img/trasllat_cancel.png";
import SelectInput from "../../SelectInput";
import TimePickerGCalendar from "../../TimePickerGCalendar";
import TrasladoPDF from "./TrasladoPDF.js";
import ValidationInput from "../../ValidationInput";
import memory from "../../../config/memory.js";
import moment from "moment";
import { withTranslation } from "react-i18next";
import SchedulePlanTransfer from "../../SchedulePlan/SchedulePlanTransfer";



const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      center={{
        lat: parseFloat(props.center.latitude),
        lng: parseFloat(props.center.longitude),
      }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      {props.customer && (
        <Marker
          key={"customer"}
          defaultLabel={""}
          className="routes-marker"
          defaultIcon={{
            url: PinSource,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.customer.latitude),
            lng: parseFloat(props.customer.longitude),
          }}
        />
      )}
      {props.nosocomio?.latitude && (
        <Marker
          key={"nosocomio"}
          defaultLabel={""}
          className="routes-marker"
          defaultIcon={{
            url: PinDestination,
            scaledSize: { width: 48, height: 48 },
            size: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15),
          }}
          position={{
            lat: parseFloat(props.nosocomio.latitude),
            lng: parseFloat(props.nosocomio.longitude)
          }}
        />
      )}

      {props.currentPosition && (
        <Marker
          key={"currentposicion"}
          defaultLabel={""}
          className="routes-marker"
          defaultIcon={{
            url: PinCurrent,
            size: { width: 48, height: 48 },
            scaledSize: { width: 48, height: 48 },
            labelOrigin: new window.google.maps.Point(20, 15)
          }}
          position={ props.currentPosition}
        />
      )}

      {props.mode && props.mode === "inprogress" ? (
        <div>
          <Polyline
            path={props.path1}
            options={{
              strokeColor: "#099107",
              strokeWeight: 7,
              strokeOpacity: 0.7,
            }}
          />
          <Polyline
            path={props.path2}
            options={{
              strokeOpacity: 0.7,
              strokeColor: "#01abc2",
              strokeWeight: 7,
            }}
          />
        </div>
      ) : (
        <div>
          {props.directions.length > 0 && (
            <Polyline
              path={props.directions[1]}
              options={{
                strokeOpacity: 0.7,
                strokeWeight: 7,
                strokeColor: "#099107",
              }}
            />
          )}
          <Polyline
            path={props.directions[0]}
            options={{
              strokeOpacity: 0.7,
              strokeColor: props.colorStroke,
              strokeWeight: 7,
            }}
          />
        </div>
      )}
    </GoogleMap>
  ))
);

class DialogoTraslado extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      open: false,
      types: [
        {
          value: t("Practice"),
          id: 1,
        },
        {
          id: 2,
          value: t("Traslado"),
        },
      ],
      nosocomios: memory.nosocomios,
      traslados: memory.traslados,
      type: "",
      module: "",
      component: null,
      current: null,
      action: "",
      address: "",
      traslado: 0,
      nosocomio: 0,
      appointmentAction: "",
      time_end_suggested: "-",
      distance_suggested: "-",
      waypoints: [],
      nosocomioObject: null,
      directions: [],
      recurrence: {},
      hospitals: [],
      startTime: "02:00",
      rawPath: null,
      path1: [],
      path2: [],
      indexPosition: 0,
      currentPosition: null,
      pathExtra: null,
      time_recorrend: 720,
      distance_recorred: 5100,
    };
  }


  componentDidMount() {
    this.getHospital()
    
    if (this.props.editData.length > 0 || this.props.item) {
      this.setDataToEdit(this.props.editData);
    }
    if ( memory.tempService?.type) {
      this.setState({
        type: memory.tempService.type,
        startTime: memory.tempService.startTime,
        startDate: memory.tempService.startDate,
        recurrence: memory.tempService.recurrence,
        nosocomios: this.props.hospitals,
      });

      this.handleValue("traslado", memory.tempService.traslado.id)
      this.handleValue("nosocomio", memory.tempService.nosocomio.id)
    } else {
      this.handleValue("startTime", "09:00");
    }
    if (this.props.viewMode === "apointment") {
      let interval = setInterval(this.timer, 1000);
      this.setState({ interval: interval });
    }

    if (!window.google) {
      let interval2 = setInterval(this.checkMap, 1000);
      this.setState({ interval2: interval2 });
    }
    this.setDirection()
  }

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
    clearInterval(this.state.interval2);
  };

 calculateTimeDifference= (startDateTime, endDateTime)=> {
    let start = moment(startDateTime);
    let end = moment(endDateTime);

    let duration = moment.duration(end.diff(start));

    let hours = Math.floor(duration.asHours());
    let minutes = duration.minutes();
    let seconds = duration.seconds();

    return `${hours.toString().padStart(2, '0')}hr ${minutes.toString().padStart(2, '0')}min ${seconds.toString().padStart(2, '0')}seg`;
}


  checkMap = () => {
    if (window.google) {
      this.setDirection();
      clearInterval(this.state.interval2);
    }
  };

  getHospital = () => {
    AppointmentApiInvoker.getHospital((response) => {
      this.setState({ hospitals: this.formatHospital(response) });
    });
  };

  formatHospital = (data) => {
    let result = data.map((c) => {
      return {
        id: c.hospitalId || '',
        latitude: c.latitude || 0,
        value: c.businessName || '',
        address: c.address || '',
        longitude: c.longitude || 0,
      };
    });
    return result;
  };

  timer = () => {
    if (this.state.rawPath != null) {
      let index = this.state.indexPosition;
      if (index >= this.state.rawPath.length) {
        clearInterval(this.state.interval);
      }
      let distance = 0;
      if (this.state.time_recorrend % 10 === 0) {
        distance = 100;
      }
      if (this.state.time_recorrend % 5 === 0) {
        this.dividePath(this.state.rawPath, index + 1);
      }

      this.setState(prevState => ({
        time_recorrend: prevState.time_recorrend + 1,
        distance_recorred: prevState.distance_recorred + distance
      }));
    }
  };

  handleValue = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === "startTime") {
        this.recalculateStartTime();
      }
    });
    if (key == "nosocomio") {
      const moso = this.props.hospitals.filter((mo) => mo.id == value);
      if (moso.length > 0) {
        this.setState(
          {
            nosocomioObject: moso[0],
          },
          () => {
            this.setDirection();
          }
        );
      }
    }
    this.props.handleChangeValue(key, value);
  };

  setDirection = (item) => {
    if (!this.props.customer || !this.state.nosocomioObject) {
      return;
    }
    let DirectionsService = null;
    try {
      DirectionsService = new window.google.maps.DirectionsService();
    } catch (error) {
      return;
    }

    let alternative = false;
    if (
      this.props.appointment &&
      this.props.appointment.appointmentId == 101 &&
      this.isTodayMenu(1)
    ) {
      alternative = true;
    }

    DirectionsService.route(
      {
        origin: {
          lat: parseFloat(this.props.customer.latitude),
          lng: parseFloat(this.props.customer.longitude),
        },
        destination: {
          lat: parseFloat(this.state.nosocomioObject.latitude ||item.latitude ),
          lng: parseFloat(this.state.nosocomioObject.longitude || item.longitude),
        },
        waypoints: [],
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: alternative,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          let distance_suggested = "-"
          let time_end = 0
          if (result.routes.length > 0 && result.routes[0].legs.length > 0) {
            distance_suggested = result.routes[0].legs[0].distance["text"];
            time_end = result.routes[0].legs[0].duration["value"]
          }
          let raw = []
          let extras = []

          if (this.props.viewMode === "apointment") {
            raw = result["routes"][0]["overview_path"];
            let startp = parseInt(raw.length / 2);
            this.dividePath(raw, startp);
          }
          if (result["routes"].length > 1) {
            result["routes"][1]["overview_path"].map(function ( value,
              index,
              array
            ) {
              extras.push(value)
            });
          }

          let directions = [];
          if (result["routes"].length > 0)
            directions.push(result["routes"][0]["overview_path"])
          if (result["routes"].length > 1)
            directions.push(result["routes"][1]["overview_path"])
          if (result["routes"].length > 2)
            directions.push(result["routes"][2]["overview_path"])
         
          this.setState(
            {
              directions: directions,
              distance_suggested: distance_suggested,
              time_end: time_end,
              rawPath: raw,
              pathExtra: extras,
            },
            () => {
              this.recalculateStartTime();
            }
          );
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  dividePath = (raw, indexPath) => {
    let path1 = [];
    let path2 = [];
    let currentPosition = {};
    raw.map(function (value, index, array) {
      if (index < indexPath) {
        path1.push(value);
      } else if (index === indexPath) {
        path1.push(value);
        path2.push(value);
        currentPosition = { latitude: value.lat(), longitude: value.lng() };
      } else {
        path2.push(value);
      }
    });
    this.setState({
      path1: path1,
      path2: path2,
      rawPath: raw,
      indexPosition: indexPath,
      currentPosition: currentPosition,
    });
  };
  formatRecureence = (data)=>{
    return{
        recurrenceType: data.recurrenceType,
        every: data.every,
        quantityIntraday: data.quantityIntraday,
        finish: data.finish,
        finishOcurrences: data.finishOcurrences,
        finishDate: data.finishDate,
        monthRecurrence: data.monthRecurrence,
        weekRecurrence: data.weekRecurrence
    }
  }

  setDataToEdit(data) {

    let hospital = null;

    if(this.props.item){
      AppointmentApiInvoker.getHospital((response) => {
        
          hospital = response.filter((id) => id.hospitalId == data.recurrence.items?.find(service => service.order == this.props.item).hospital.hospitalId);
          this.setState({
            traslado:
               this.formatTransferTypeSend(data.serviceName)
                || "",
            nosocomio: data.recurrence.items?.find(service => service.order == this.props.item).hospital.hospitalId || null,
            nosocomioObject: hospital[0],
            startDate: data.recurrence.items?.find(service => service.order == this.props.item).startDate || null,
            startTime: data.recurrence.items?.find(service => service.order == this.props.item).startTime || null,
            recurrence:
              this.formatRecureence(data.recurrence.items?.find(service => service.order == this.props.item))  || null,
          });
        this.setDirection(hospital[0])
      });
    }else{
      AppointmentApiInvoker.getHospital((response) => {
        if (data.length > 0) {
          hospital = response.filter((id) => id.hospitalId == data[0].recurrence.items?.find(service => service.transferItemRequestId == this.props.itemSelect).hospital.hospitalId);
          this.setState({
            traslado:
              data.length > 0
                ? this.formatTransferTypeSend(data[0].serviceName)
                : "",
            nosocomio: data.length > 0 ? data[0].recurrence.items.find(service => service.transferItemRequestId == this.props.itemSelect).hospital.hospitalId : null,
            nosocomioObject: hospital[0],
            startDate: data.length > 0 ? data[0].recurrence.items.find(service => service.transferItemRequestId == this.props.itemSelect).startDate : null,
            startTime: data.length > 0 ? data[0].recurrence.items.find(service => service.transferItemRequestId == this.props.itemSelect).startTime : null,
            recurrence:
            this.formatRecureence(data[0].recurrence.items.find(service => service.transferItemRequestId == this.props.itemSelect))  || null,
          });
        }
      });
    }
  }

  formatTransferTypeSend = (type) => {
    if (type === "Traslado estándar") {
      return 1;
    } else if (type === "Traslado con rampa") {
      return 2;
    } else if (type === "Traslado con ambulancia") {
      return 3;
    }
  };

  recalculateStartTime = () => {
    this.setState(prevState => {
      let start = moment(prevState.startTime, "HH:mm"); 
      start = start.subtract(prevState.time_end, "seconds"); 
  
      return {
        time_end_suggested: start.format("HH:mm"),
      };
    });
  };

  componentWillReceiveProps(next) {
    this.setState({ open: next.open });
  }

  closeModal() {
    this.setState({
      open: false,
      type: "",
      component: null,
      current: null,
    });
    this.props.onCloseModal();
  }



  onCreatedRecurrence = (value) => {
    this.setState({
      recurrence: value,
    });
    this.props.handleChangeValue("recurrence", value);
  };

  invalidPlan = () => {
    return false;
  };

  

  formatTimeSeconds = () => {
    let totalSeconds = this.state.time_recorrend;
    let hours = Math.floor(totalSeconds / 3600) + "";
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60) + "";
    let seconds = (totalSeconds % 60) + "";
    return (
      hours.padStart(2, "0") +
      ":" +
      minutes.padStart(2, "0") +
      ":" +
      seconds.padStart(2, "0")
    );
  };

  isTodayMenu = (v) => {
    let nowmenusone = moment(new Date())
      .add(v * -1, "d")
      .format("YYYY-MM-DD");
    let event = moment(new Date(this.props?.event?.start)).format("YYYY-MM-DD");
    return nowmenusone === event;
  };

  formatDistance = (valueInMeters) => {
    if (typeof valueInMeters !== 'number') {
      return 'Valor inválido';
    }
  
    const absoluteValue = Math.abs(valueInMeters);
  
    if (absoluteValue < 1000) {
      return `${absoluteValue} m`;
    }
  
    const kilometers = (absoluteValue / 1000).toFixed(2);
    return `${kilometers} km`;
  };

  getGeoTrackDistance = (schedules) => {
    if (!schedules || !Array.isArray(schedules)) {
      return null;
    }
  
    const scheduleWithDistance = schedules.find(schedule => 
      schedule.geoTrackDistance !== null && schedule.geoTrackDistance !== undefined
    );
  
    return scheduleWithDistance ? scheduleWithDistance.geoTrackDistance : null;
  };  

  toPDF = () => {
    const { t } = this.props;
    let hora_real_retiro = "";
    let hora_real_fin = "";
    let hora_real_distance = "";
    let time_real = "";

    const geoTrackDistance = this.getGeoTrackDistance(this.props?.appointment?.schedules);
    const formattedDistance = geoTrackDistance ? this.formatDistance(geoTrackDistance) : 'Sin distancia';

    if (this.isTodayMenu(1)) {
      hora_real_retiro = "10:04";
      time_real = "00:25:00";
      hora_real_fin = "10:29";
      hora_real_distance = "10.1 km";
    } else if (this.isTodayMenu(2)) {
      hora_real_retiro = "10:00";
      hora_real_distance = "10.4 km";
      hora_real_fin = "10:30";
      time_real = "00:30:00";
    } else if (this.isTodayMenu(3)) {
      hora_real_retiro = "9:58";
      hora_real_distance = "10.2 km";
      hora_real_fin = "10:26";
      time_real = "00:28:00";
    } else if (this.isTodayMenu(4)) {
      hora_real_retiro = "10:02";
      hora_real_fin = "10:35";
      time_real = "00:33:00";
      hora_real_distance = "10.2 km";
    } else if (this.isTodayMenu(5)) {
      hora_real_retiro = "10:04";
      hora_real_fin = "10:32";
      hora_real_distance = "10.3 km";
      time_real = "00:28:00";
    }
    const transferType = this.props?.appointment?.schedules?.find(schedule => schedule.transferScheduleId === this.props.event.id).transferItemRequestId.transferType
    const addressNumber = this.props?.appointment?.schedules?.find(schedule => schedule.transferScheduleId === this.props.event.id).address.number
    let context = {
      appointmentId: this.props.event.id,
      startDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      startTime: moment(this.props?.event?.origin?.startDateTime).format("HH:mm"),
      realDate: moment(new Date(this.props.event.start)).format("DD/MM/YYYY"),
      realTime: moment(this.props?.event?.origin?.endDateTime).format("HH:mm"),
      address_fin: this.props.event.origin.transferItemRequestId.hospital.address,
      trasladoName: t(`status.transfer.${transferType}`),
      financier:{
        financier: this.props?.appointment?.financier?.financiers?.[0]?.financier?.businessName,
        provider: this.props?.appointment?.schedules?.[0]?.transferItemRequestId?.companyIdPartnership?.name
      },
      customer: {
        firstName: this.props.appointment.customer.name,
        lastName: this.props.appointment.customer.lastname,
        address: `${this.props.appointment.customer.address_name} ${addressNumber}`,
        location: this.props.appointment.customer.zone,
        province: this.props.appointment.customer.province,
        typeDocument: this.props.appointment.customer.typeDocument,
        document: this.props.appointment.customer.identity
      },
      chofer: {
        firstName: this.props.event.origin.transferItemRequestId.driver.firstName,
        lastName: this.props.event.origin.transferItemRequestId.driver.lastName,
        typeDocument: this.props.event.origin?.transferItemRequestId.driver?.idType?.name || 'DNI',
        document: this.props.event.origin.transferItemRequestId.driver.id,
      },
      nosocomio: {
        address: this.props.event.origin.transferItemRequestId.hospital
        .businessName +
        " " +
        this.props.event.origin.transferItemRequestId.hospital.address,
        location: "Capital",
        province: "Córdoba",
      },
      trayecto: {
        distance: formattedDistance,
        duration: this.calculateTimeDifference(this.props?.event?.origin.startDateTime, this.props?.event?.origin.endDateTime),
        coordOmission: false,
        // coordOmission: this.props?.event?.origin?.coordOmission || false,
        // geoTrackDistance: this.props?.event?.origin?.geoTrackDistance ?? 0,
      },
    };
    TrasladoPDF(context);
  };

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  render() {
    const { t } = this.props;
    let end_time = "end_time";
    let viewMode = "short";
    let center = this.props.customer;
    if (this.props.viewMode && this.props.type !== "calendar") {
      viewMode = this.props.viewMode;
    } else {
      viewMode =
        this.props.appointment &&
        (this.props.appointment.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED"
           || this.props.appointment.appointmentStatus === "ENDED")
          ? "large"
          : "short";
    }
    if (this.state.currentPosition) {
      center = this.state.currentPosition
    }

    if (viewMode == "large") {
      let nextDay = false;
      let today = new Date()
      let dateEvent;
      if (this.props.event) {
        dateEvent = new Date(this.props.event.start);
      }
      if (dateEvent > today) {
        nextDay = true;
      }
      let istoday = this.isTodayMenu(0);
      let todaymenusone = this.isTodayMenu(1)
      let todaymenustwo = this.isTodayMenu(2)

      let icon = Pending;
      let status = "PENDING_APPROVAL";
      if(this.props.appointment?.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED"
        || this.props.appointment?.appointmentStatus === "ENDED"){
          status = this.props?.event?.origin?.transferScheduleStatus
      }
      
      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 100
      ) {
        icon = Reject;
        status = "REJECTED";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101
      ) {
        if (istoday) {
          icon = PendingDo;
          status = "IN_PROGRESS";
        } else if (nextDay) {
          icon = PendingDo;
          status = "APPROVED";
        } else if (todaymenusone || todaymenustwo) {
          icon = Finished;
          status = "DONE";
        } else if (this.props.appointment.appointmentStatus === "ENDED") {
          icon = Finished;
          status = "ENDED";
        }else {
          icon = Finished;
          status = "DONE";
        }
      }

      let color = "#099107";
      if (nextDay) {
        color = "#01abc2";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 101 &&
        this.isTodayMenu(1)
      ) {
        color = "#01abc2";
      }

      if (
        this.props.appointment &&
        this.props.appointment.appointmentId == 100
      ) {
        color = "#01abc2";
      }
      let hora_real_retiro = "-";
      let hora_real_fin = "-";
      let hora_real_distance = "-";
      let finished = false;
      if (
        this.props.appointment
        
      ) {
        if (this.isTodayMenu(0)) {
          hora_real_retiro = "10:04";
          color = "#01abc2";
        }
        if (this.isTodayMenu(1)) {
          hora_real_retiro = "10:04";
          hora_real_fin = "10:29";
          hora_real_distance = "10.1 km";
          finished = true;
        } else if (this.isTodayMenu(2)) {
          hora_real_retiro = "10:00";
          hora_real_fin = "10:30";
          hora_real_distance = "10.4 km";
          finished = true;
        } else if (this.isTodayMenu(3)) {
          hora_real_retiro = "9:58";
          hora_real_fin = "10:26";
          hora_real_distance = "10.2 km";
          finished = true;
        } else if (this.isTodayMenu(4)) {
          hora_real_retiro = "10:02";
          hora_real_fin = "10:35";
          hora_real_distance = "10.2 km";
          finished = true;
        } else if (this.isTodayMenu(5)) {
          hora_real_retiro = "10:04";
          hora_real_fin = "10:32";
          hora_real_distance = "10.3 km";
          finished = true;
        }
      }
      //const [transfer, setTransfer] = useState;
      console.log("aca entro");
      console.log(this.props.event)
      console.log(viewMode);
      let datestart = this.props.event?.origin?.startDate;

      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("Traslado")}
                  disabled={true}
                  value={this.formatTransferTypeRecive(
                    this.props?.event?.origin?.transferItemRequestId?.transferType
                  )}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("N° Traslado")}
                  disabled={true}
                  value={this.props.event?.id}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t("Chofer")}
                  disabled={true}
                  value={
                    this.props.event?.origin?.driver
                      ? this.props.event?.origin?.driver.firstName +
                        ", " +
                        this.props.event?.origin?.driver.lastName
                      :   
                          this.props.event?.origin?.transferItemRequestId?.driver
                      
                        ? this.props.event?.origin?.transferItemRequestId?.driver?.firstName +
                          ", " +
                          this.props.event?.origin?.transferItemRequestId?.driver?.lastName
                        : 
                          "-"
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t('label.startDate')}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={`${moment(
                    this.props.event?.origin.startDateTime
                  ).format("DD/MM/YYYY")}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t('service.source.address')}
                  disabled={true}
                  inputProps={{ style: { width: "100%" } }}
                  value={this.props?.appointment?.customer.address_name}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t('service.stimated.pickup.time')}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  value={`${
                    this.props?.event?.origin.startTime ?
                      this.props?.event?.origin.startTime 
                    : this.props?.event?.origin.transferItemRequestId.startTime
                  }`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText={t('service.real.pickup.time')}
                  disabled={true}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={`${moment(
                    this.props?.event?.origin.startDateTime
                  ).format("HH:mm")}`}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  text={t('service.hospital.destination')}
                  elements={""}
                  disabled={true}
                  value={
                    this.props?.event?.origin?.transferItemRequestId.hospital
                      .businessName +
                    " " +
                    this.props?.event?.origin?.transferItemRequestId.hospital
                      .address
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <CustomInput
                  labelText={t('service.stimated.arrival.time')}
                  disabled={true}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  value={`${
                    this.props?.event?.origin?.endTime ? 
                    this.props?.event?.origin?.endTime :
                    this.props?.event?.origin?.transferItemRequestId.endTime
                  }`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={t('service.real.arrival.time')}
                  value={this.props?.event?.origin?.geoDistance ? moment(this.props?.event?.origin?.endDateTime).format("HH:mm") : '-'}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={t('service.estimated.distance')}
                  value={`${this.props?.event?.origin?.transferItemRequestId.distanceRoundTrip} Km`}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  type="text"
                  disabled={true}
                  labelText={t('service.real.distance')}
                  value={this.props?.event?.origin?.geoDistance ? `${(this.props?.event?.origin?.geoDistance / 1000).toFixed(2) } Km` : "-"}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className="no-padding">
                <ValidationInput
                  type="text"
                  iconName={icon}
                  disabled={true}
                  inputProps={{ style: { width: "100%" } }}
                  text={t('status')}
                  value={`${status === 'PENDING' ? t('common.pending'): status === 'IN_PROGRESS'? t('En curso'): status === 'REJECTED'? t('status.REJECTED') : status === 'PENDING_APPROVAL'? t('Por aprobar') : status === 'APPROVED'? t('Por realizar') : status === 'DONE'? t('Finalizado') : t(status)}`}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customer={this.props.customer}
                location={this.state.currentLocation}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                mode=""
                center={center}
                colorStroke={color}
                nosocomio={
                  this.props.event?.origin.transferItemRequestId.hospital
                }
                loadingElement={<div style={{ height: `100%` }} />}
                googleMapURL="/googlemap"
                containerElement={
                  <div
                    style={{
                      height: `500px`,
                      overflow: "hidden",
                      borderRadius: "6px",
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            <GridContainer style={{ textAlign: "right" }}>
              <GridItem xs={12} sm={12} md={12}>
                {status === "DONE" && (
                  <Button
                    onClick={() => {this.toPDF()}}
                    color="warning"
                    style={{ textAlign: "right" }}
                  >
                    {t('download.document.pdf')}
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )
    
    } else if (viewMode === "edit" || viewMode === "assign_agenda") {
      let min, max;
      if (!this.props.disabled) {
        min = moment(new Date(this.props.provider.dateStart)).format(
          "YYYY-MM-DD"
        );
        max = moment(new Date(this.props.provider.dateEnd)).format(
          "YYYY-MM-DD"
        );
      }
      return (
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} className="componentType">
            <GridItem xs={12} sm={12} md={12}>
              <SelectInput
                label={t("service.traslado")}
                elements={this.state.traslados}
                disabled={this.props.disabled}
                onSelectedValue={(value) => this.handleValue("traslado", value)}
                value={this.state.traslado}
              />
            </GridItem>
            <GridItem xs={12} className="margin-left">
              <h4 className="label-services">{t("service.destino")}</h4>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
            <ValidationInput
                type="text"
                text={`${t("service.nosocomio")}`}
                
                
                disabled={true}
                onSelectedValue={(value) =>
                  this.handleValue("nosocomio", value)
                }
                
                value={this.state.hospitals?.find(t => t.id === this.state.nosocomio)?.value || ''}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationInput
                type="text"
                text={`${t("service.address")}`}
                disabled={true}
                value={
                  this.state.nosocomioObject
                    ? this.state.nosocomioObject.address
                    : ""
                }
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
                <DateInput
                  text={t("service.start_date")}
                  disabled={this.props.disabled}
                  minDate
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.startDate}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className="timeInput">
                <TimePickerGCalendar
                  text={t("service.requested.pickup.time")}
                  value={this.state.startTime}
                  name={end_time}
                  disabled={this.props.disabled}
                  onSelectedValue={(value) =>
                    this.handleValue("startTime", value)
                  }
                  defaultValue="02:00"
                  invalid={!this.state.startTime}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SchedulePlanTransfer
                  isTraslado={true}
                  indexArrayDays={0}
                  disabled={this.props.disabled}
                  insideModule={false}
                  current={this.state.recurrence}
                  invalidPlan={this.invalidPlan}
                  onCreatedRecurrence={this.onCreatedRecurrence}
                  className="schedule-plan-component"
                  onlyDate={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  text={`${t("service.distance")}`}
                  type="text"
                  disabled={this.props.disabled}
                  value={this.state.distance_suggested}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ValidationInput
                  type="text"
                  disabled={this.props.disabled}
                  text={`${t("service.time_start_destination")}`}
                  value={this.state.time_end_suggested}
                />
              </GridItem>
            </GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customer={this.props.customer}
                directions={this.state.directions}
                waypoints={this.state.waypoints}
                location={this.state.currentLocation}
                nosocomio={
                  this.state.appointment
                    ? this.state.appointment.nosocomio
                    : this.state.nosocomioObject
                }
                googleMapURL="/googlemap"
                mode=""
                center={center}
                colorStroke={"#01abc2"}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `500px`,
                      overflow: "hidden",
                      width: `100%`,
                      borderRadius: "6px",
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
            {this.props.appointment && (
              <GridContainer className="button-translado">
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => this.props.openDrivers()}
                    color="primary"
                  >
                    {t("service.driver.assign")}
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    color={"success"}
                    onClick={() => this.props.saveService()}
                  >
                    {t("service.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
        </GridContainer>
      );
    } else {
      let date = new Date();
      date = moment(date).format("DD-MM-YYYY")
      let distance_recorred_str = this.state.distance_recorred / 1000 + " Km";
      let time_recorred_str = this.formatTimeSeconds();
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className="componentType">
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  labelText={t("service.scheduleId")}
                  disabled={true}
                  value={this.props.event?.id}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={this.props.disabled}
                  labelText={t("service.start_date")}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={date}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  text={t("service.customer.address")}
                  disabled={true}
                  inputProps={{ style: { width: "100%" } }}
                  value={this.props.appointment.customer.address_name}
                />
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.startTimeProgReal")}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.endTimeEstimated")}
                  labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                  onChangeValue={(value) =>
                    this.handleValue("startDate", value)
                  }
                  value={this.state.time_end_suggested}
                />
              </GridItem>

              <GridItem xs={3} sm={3} md={3}>
                <CustomInput
                  labelText={t("service.traslado_detail")}
                  disabled={true}
                  value={memory?.tempService?.traslado?.value}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <CustomInput
                  disabled={true}
                  labelText={t("service.driver")}
                  value={
                    memory?.tempService?.driver
                      ? memory?.tempService?.driver?.value
                      : "-"
                  }
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <ValidationInput
                  text={t("service.nosocomio.address")}
                  disabled={true}
                  elements={this.state.nosocomios}
                  value={
                    memory?.tempService?.nosocomio?.value +
                    " - " +
                    memory?.tempService?.nosocomio?.address
                  }
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  text={t("service.distance_recorred")}
                  disabled={true}
                  value={distance_recorred_str}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} className="no-padding">
                <ValidationInput
                  disabled={true}
                  text={t("service.time_recorred")}
                  value={time_recorred_str}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer></GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <RegularMap
                customer={this.props.customer}
                waypoints={this.state.waypoints}
                location={this.state.currentLocation}
                directions={this.state.directions}
                center={center}
                path1={this.state.path1}
                path2={this.state.path2}
                nosocomio={
                  this.props?.appointment
                    ? this.props.appointment?.services?.nosocomio
                    : {}
                }
                mode="inprogress"
                googleMapURL="/googlemap"
                colorStroke={"#01abc2"}
                pathExtra={this.state.pathExtra}
                currentPosition={this.state.currentPosition}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      borderRadius: "6px",
                      overflow: "hidden",
                      height: `500px`,
                      width: `100%`,
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
      );
    }
  }
}

DialogoTraslado.defaultProps = {
  onGetModuleTypes: () => [],
  onGetPracticeTypes: () => [],
};

DialogoTraslado.propTypes = {
  t: PropTypes.func,
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onCreatedModule: PropTypes.func,
  onGetModuleTypes: PropTypes.func,
  onGetPracticeTypes: PropTypes.func,
  appointment: PropTypes.object,
  viewMode: PropTypes.string,
  customer: PropTypes.object,
  itemSelect: PropTypes.object,
};

export default withTranslation()(DialogoTraslado);
